import React from 'react';
import Recommendations from './Recommendations';
import './OnSale.module.css';

const OnSale = props => (
  <div styleName="root">
    <h3 styleName="title">Распродажа дня</h3>
    <Recommendations {...props} />
  </div>
);

export default OnSale;
