import React from 'react';

export default Component =>
  class extends React.Component {
    state = {
      isClient: false,
    };

    componentDidMount() {
      this.setState({ isClient: true });
    }

    render() {
      return <Component {...this.props} {...this.state} />;
    }
  };
