import { useEffect } from 'react';

const SECOND_HEADER_HEIGHT = 85;
const MOBILE_HEADER_HEIGHT = 166;

export default function scrollToCategory(id) {
  const el = document.getElementById(`category-${id}`);

  if (!el) {
    return;
  }

  const headerHeight = window.innerWidth <= 666 ? MOBILE_HEADER_HEIGHT : SECOND_HEADER_HEIGHT;

  const rect = el.getBoundingClientRect();
  const diff = rect.top - headerHeight;

  if (Math.abs(diff) < 50) {
    return;
  }

  const top = window.pageYOffset + diff;

  /* eslint-disable consistent-return */
  window.scrollTo({ top, behavior: 'smooth' });
}

export const useInitialScroll = () => {
  useEffect(() => {
    const t = setTimeout(() => {
      const m = window.location.hash.match(/#c(\d+)/);

      if (m) {
        scrollToCategory(m[1], window.location.pathname.indexOf('/mob') === 0);
      }
    }, 1000);

    return () => clearTimeout(t);
  }, [typeof window !== 'undefined' && window.location.hash]);
};
