import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withAvoidHydrate from 'hocs/withAvoidHydrate';
import CategoryRow from 'components/CategoryRow';
import CategoryFooter from 'components/CategoryFooter';
import scrollToCategory from 'utils/scrollToCategory';
import { useVirtualPageObserver } from 'components/hooks';
import { viewPage } from 'utils/analytics';
import './Category.module.css';

const smoothIncrease = (set, limit) => {
  const next = () => {
    set(current => {
      if (current === limit) {
        return current;
      }

      requestAnimationFrame(next);
      return current + 1;
    });
  };

  next();
};

const Category = ({ data, isClient, perRow, onlyOnClient, ...props }) => {
  const { entity_id: entityId, name, products } = data;

  const rootRef = useVirtualPageObserver();
  const [rowLimit, setRowLimit] = useState(products.length > perRow * 2 ? 1 : 2);
  const moreCount = products.length - perRow;
  const virtualPath = `/catogery-${entityId}.html`;
  const totalRowCount = Math.ceil(products.length / perRow);

  if (!isClient && onlyOnClient) {
    return null;
  }

  const toggle = () => {
    if (rowLimit > 1) {
      setRowLimit(1);
      viewPage('/');
    } else {
      smoothIncrease(setRowLimit, totalRowCount);
      viewPage(`/full-catogery-${entityId}.html`);
    }

    scrollToCategory(entityId);
  };

  const rows = [];
  const firstIds = products
    .slice(0, 3)
    .map(p => p.entity_id)
    .join(',');

  const ssrLimit = isClient ? rowLimit : 1;

  for (let i = 0; i < ssrLimit; i += 1) {
    rows.push(
      <CategoryRow key={`c-${entityId}-${i}`} products={products} from={i * perRow} to={(i + 1) * perRow} {...props} />
    );
  }

  return (
    <div
      styleName="category"
      key={entityId}
      id={`category-${entityId}`}
      data-path={virtualPath}
      data-ids={firstIds}
      ref={rootRef}
    >
      <h4 styleName="category-title">{name}</h4>
      {rows}
      {moreCount > 0 && <CategoryFooter opened={rowLimit > 1} moreCount={moreCount} onMore={toggle} data={data} />}
    </div>
  );
};

Category.propTypes = {
  data: PropTypes.object.isRequired,
  perRow: PropTypes.number.isRequired,
  isClient: PropTypes.bool.isRequired,
  onlyOnClient: PropTypes.bool,
};

Category.defaultProps = {
  onlyOnClient: false,
};

export default withAvoidHydrate(Category);
