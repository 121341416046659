import React from 'react';
import PropTypes from 'prop-types';
import ProductCard from './ProductCard';
import Carousel from './Carousel';
import { useDefferedArray } from './hooks';
import './Recommendations.module.css';

const Recommendations = ({ products, perRow, classNameOfCard, onlyDetox }) => {
  const currentProducts = onlyDetox ? products.filter(p => p.detox) : products;
  const items = useDefferedArray(currentProducts);

  return (
    <div styleName="root">
      <Carousel slidesToShow={perRow} slidesToScroll={perRow} initialSlide={0} key={perRow}>
        {items.map(p => (
          <ProductCard className={classNameOfCard} styleName="item" data={p} key={p.entity_id} />
        ))}
      </Carousel>
    </div>
  );
};

Recommendations.propTypes = {
  products: PropTypes.array.isRequired,
  perRow: PropTypes.number.isRequired,
  classNameOfCard: PropTypes.string,
  onlyDetox: PropTypes.bool.isRequired,
};

Recommendations.defaultProps = {
  classNameOfCard: undefined,
};

export default Recommendations;
