import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { graphql } from 'gatsby';
import { useInitialScroll } from 'utils/scrollToCategory';
import Analytics from 'components/Analytics';

import MobileHeader from 'components/MobileHeader';
import SearchContext from 'components/SearchContext';
import Hello from 'components/Hello';
import Search from 'components/Search';
import Reviews from 'components/Reviews';
import Recommendations from 'components/Recommendations';
import OnSale from 'components/OnSale';
import MobileFooter from 'components/MobileFooter';
import ProductPlane from 'components/ProductPlane';

import './index.module.css';

const MobPage = ({ data, onlyDetox }) => {
  const { debouncedQuery } = useContext(SearchContext.Value);
  const { onsale, recommendations, categories } = data.magento.cachedCatalog;
  const perRow = 2;

  useInitialScroll();

  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="width=666, user-scalable=no" />
      </Helmet>
      <Analytics type="viewHome" />
      <MobileHeader onlyDetox={onlyDetox} />
      <Hello style={{ marginTop: 175 }} />
      {recommendations.length > 0 && (
        <Recommendations onlyDetox={onlyDetox} products={recommendations[0].products} perRow={perRow} />
      )}
      <Search mob iconColor="black" />
      {!debouncedQuery && <Reviews reviews={data.magento.reviews} perRow={perRow} />}
      {onsale.length > 0 && !debouncedQuery && <OnSale onlyDetox={onlyDetox} products={onsale} perRow={perRow} />}
      <ProductPlane categories={categories} perRow={perRow} onlyDetox={onlyDetox} />
      <MobileFooter />
    </React.Fragment>
  );
};

MobPage.propTypes = {
  data: PropTypes.object.isRequired,
  onlyDetox: PropTypes.bool,
};

MobPage.defaultProps = {
  onlyDetox: false,
};

export const query = graphql`
  query MobQuery {
    ...HomeData
  }
`;

export default MobPage;
