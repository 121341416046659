import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Arrow from 'components/icons/Arrow';
import './ToCatalog.module.css';

const GoBack = ({ size }) => (
  <Link to="/" styleName="arrow-back-wrapper">
    <Arrow color="orange" size={size} styleName="arrow-back" />
    <p styleName="arrow-back-text">В каталог</p>
  </Link>
);

GoBack.propTypes = {
  size: PropTypes.number.isRequired,
};

export default GoBack;
