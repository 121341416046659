import React from 'react';
import PropTypes from 'prop-types';
import './Bages.module.css';

const Bages = ({ data }) => {
  const discount = data.price && 100 - Math.round((data.finalPrice / data.price) * 100);
  const n = Number(data.warehouse_qty);
  return (
    <React.Fragment>
      {data.text_badge && <div styleName="bage" data-bage={data.text_badge} />}
      {data.eco > 0 && <div styleName="euro-sheet" />}
      {data.organic > 0 && <div styleName="organic" />}
      {data.raw > 0 && (
        <div styleName="raw-and-vegan_containter">
          <div styleName="raw-and-vegan" />
        </div>
      )}
      {data.season && (
        <div styleName="season_container">
          <div data-color={data.season.color} data-text={data.season.text} styleName="season" />
        </div>
      )}
      {discount > 0 && <div data-discount={`-${discount}%`} styleName="discount" />}
      {data.averageConsumption && (n !== 0 || data.averageConsumption / 2 > n) && <div styleName="low-remain" />}
    </React.Fragment>
  );
};

Bages.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Bages;
