import React from 'react';
import PropTypes from 'prop-types';
import ModalLink from 'components/ModalLink';

import './Account.module.css';

const Account = ({ className, size, ...props }) => (
  <ModalLink component="button" type="button" styleName={`root size-${size}`} className={className} {...props} />
);

Account.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

Account.defaultProps = {
  className: undefined,
  size: 30,
};

export default Account;
