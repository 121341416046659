import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Hamburger from 'components/icons/Hamburger';
import ToCatalog from 'components/ToCatalog';
import Account from 'components/Account';
import MiniCart from 'components/MiniCart';
import scrollToCategory from 'utils/scrollToCategory';
import SearchContext from 'components/SearchContext';
import './MobileHeader.module.css';

const withDetox = category => category.products.some(p => p.detox);

const MobileHeader = ({ onProduct, onlyDetox }) => {
  const [showMenu, toggleMenu] = useReducer(v => !v, false);
  const { setQuery } = useContext(SearchContext.Value);

  return (
    <StaticQuery
      query={graphql`
        query MobileHeader {
          magento {
            cachedCatalog {
              categories {
                entity_id
                name
                products(all: true) {
                  detox
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { categories } = data.magento.cachedCatalog;
        const newCats = onlyDetox ? categories.filter(withDetox) : categories;

        return (
          <div styleName="root">
            <div styleName="toolbar">
              {!onProduct ? (
                <React.Fragment>
                  <Hamburger styleName="hamburger" onClick={toggleMenu} />
                  <Account size={50} to="my" />
                </React.Fragment>
              ) : (
                <ToCatalog size={50} />
              )}
              <MiniCart />
            </div>
            {showMenu && (
              <div styleName="category-list">
                <div styleName="category-items">
                  {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  {newCats.map(cat => {
                    const onClick = () => {
                      setQuery('');
                      toggleMenu();
                      scrollToCategory(cat.entity_id, true);
                    };

                    return (
                      <div styleName="category" key={cat.entity_id} onClick={onClick}>
                        {cat.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

MobileHeader.propTypes = {
  onlyDetox: PropTypes.bool,
  onProduct: PropTypes.bool,
};

MobileHeader.defaultProps = {
  onProduct: false,
  onlyDetox: false,
};

export default MobileHeader;
