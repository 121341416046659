import React, { useContext } from 'react';
import Button from 'components/Button';
import SearchContext from 'components/SearchContext';
import './SearchEmptyState.module.css';

const SearchEmptyState = () => {
  const { setQuery } = useContext(SearchContext.Value);
  const backToCatalog = () => setQuery('');
  return (
    <div styleName="root" id="category-search">
      <h2 styleName="title">Ничего не нашлось</h2>
      <p styleName="subtitle">Попробуйте сформулировать запрос по-другому или вернитесь в каталог</p>
      <Button styleName="button" onClick={backToCatalog} color="light">
        Вернуться в каталог
      </Button>
    </div>
  );
};

export default SearchEmptyState;
