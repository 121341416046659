import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useDefferedArray } from './hooks';
import Carousel from './Carousel';
import Review from './Review';
import './Reviews.module.css';

const Reviews = ({ reviews, perRow }) => {
  const items = useDefferedArray(reviews, 5000);

  return (
    <div styleName="root">
      <h2 styleName="title">Каждую неделю в 1000 семей мы приносим радость! Отзывы клиентов:</h2>
      <Carousel slidesToShow={perRow} slidesToScroll={perRow} initialSlide={0} key={perRow}>
        {items.map(r => (
          <Review styleName="center-review" key={r.id} data={r} />
        ))}
      </Carousel>
    </div>
  );
};

Reviews.propTypes = {
  reviews: PropTypes.array.isRequired,
  perRow: PropTypes.number.isRequired,
};

export const query = graphql`
  fragment Reviews on Magento {
    reviews {
      id
      ...Review
    }
  }
`;

export default Reviews;
