import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SearchContext from 'components/SearchContext';
import Category from 'components/Category';
import SearchEmptyState from 'components/SearchEmptyState';
import { useAlgoliaSearch } from './hooks';

const getSearchCategory = (query, categories, hits) => {
  const products = [];
  const term = query.toLowerCase();
  const map = {};

  categories.forEach(c => {
    c.products.forEach(p => {
      if (hits) {
        map[`product::${p.entity_id}`] = p;
      } else if (p.name.toLowerCase().indexOf(term) > -1 && products.every(el => el.entity_id !== p.entity_id)) {
        products.push(p);
      }
    });
  });

  if (hits) {
    hits.forEach(h => {
      if (map[h.objectID]) {
        products.push(map[h.objectID]);
      }
    });
  }

  const searchCategory = {
    entity_id: 'search',
    name: `Поиск по запросу "${query}"`,
    products,
  };

  return [searchCategory];
};

const withoutTox = categories =>
  categories.map(category => ({
    ...category,
    products: category.products.filter(p => p.detox),
  }));

const ProductPlane = ({ categories, perRow, onlyDetox }) => {
  const { debouncedQuery } = useContext(SearchContext.Value);
  const detoxedCategories = onlyDetox ? withoutTox(categories) : categories;
  const searchHits = useAlgoliaSearch(debouncedQuery);
  const currentCategories = debouncedQuery
    ? getSearchCategory(debouncedQuery, detoxedCategories, searchHits)
    : detoxedCategories;

  return currentCategories.map((c, index) => {
    if (c.entity_id === 'search' && c.products.length === 0) {
      return <SearchEmptyState />;
    }

    if (c.products.length === 0) {
      return null;
    }

    return <Category key={c.entity_id} data={c} perRow={perRow} onlyOnClient={index > 3} rowIndex={index} />;
  });
};

ProductPlane.propTypes = {
  categories: PropTypes.array.isRequired,
  perRow: PropTypes.number.isRequired,
  onlyDetox: PropTypes.bool,
};

ProductPlane.defaultProps = {
  onlyDetox: false,
};

export default ProductPlane;
