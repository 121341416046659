import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import withAvoidHydrate from 'hocs/withAvoidHydrate';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import cn from 'classnames';
import l from './LimitedRow.module.css';
import c from './Carousel.module.css';

const Carousel = ({ children, isClient, ...props }) => {
  // ssr
  if (!isClient) {
    return <div className={cn(c.root, c.ssr, l.root)}>{children}</div>;
  }

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    lazyLoad: true,
    ...props,
  };

  return (
    <div className={c.root}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.array.isRequired,
  isClient: PropTypes.bool.isRequired,
};

export default withAvoidHydrate(Carousel);
