import React from 'react';
import Phone from 'components/Phone';
import { Link } from 'gatsby';

import './MobileFooter.module.css';

const MobileFooter = () => (
  <div styleName="root">
    <a styleName="icon-wrapper link" href="https://www.facebook.com/moscowfresh">
      {' '}
      <div data-icon="facebook" styleName="icon" />
    </a>
    <a styleName="icon-wrapper link" href="https://www.instagram.com/moscow_fresh/">
      {' '}
      <div data-icon="instagram" styleName="icon" />
    </a>
    <Link styleName="link" to="about-us">
      О сервисе
    </Link>
    <Link styleName="link" to="/uslovija-dostavki/">
      Условия доставки
    </Link>
    {/* <Link styleName="inline-link" to="/privacy-policy-cookie-restriction-mode/">
      Пользовательское соглашение
    </Link> */}
    <br />
    <span styleName="text">Вопросы? Пишите или звоните:</span>
    <a href="mailto:client@moscowfresh.ru">client@moscowfresh.ru</a>
    <br />
    <Phone styleName="phone" />
    <br />
    <br />
    <span styleName="text">{`© 2015-${new Date().getFullYear()} MoscowFresh`}</span>
    <br />
    <br />
  </div>
);

export default MobileFooter;
