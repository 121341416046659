import React from 'react';
import PropTypes from 'prop-types';
import plural from 'plural-ru';
import Button from './Button';
import './CategoryFooter.module.css';

const CategoryFooter = ({ opened, onMore, moreCount }) => (
  <div styleName="more-wrapper">
    <Button styleName="more-button" onClick={onMore}>
      {opened ? 'свернуть' : `+ ещё ${moreCount} ${plural(moreCount, 'продукт', 'продукта', 'продуктов')}`}
    </Button>
  </div>
);

CategoryFooter.propTypes = {
  onMore: PropTypes.func.isRequired,
  moreCount: PropTypes.number.isRequired,
  opened: PropTypes.bool.isRequired,
};

export default CategoryFooter;
