import React from 'react';
import Logo from './icons/Logo';
import AnimatedLogo from './AnimatedLogo';
import s from './Hello.module.css';

const Hello = props => (
  <div {...props}>
    <Logo styleName="logo" />
    <div>
      <div>
        <div styleName="benefit-title">
          <span styleName="icon" />
          <h1 styleName="label">Экспресс-доставка свежих вкусных продуктов</h1>
          <AnimatedLogo eyesClassName={s.eyes} styleName="animated-logo" />
        </div>
        {/* <div styleName="message">После 18:00 заказы принимаем на завтра</div> */}
      </div>
    </div>
    <h2 styleName="recommendations">Сегодня особенно рекомендуем:</h2>
  </div>
);

export default Hello;
