import React from 'react';
import { graphql } from 'gatsby';
import IndexPage from './mob';

export default props => <IndexPage {...props} onlyDetox />;

export const query = graphql`
  query VeganMobQuery {
    ...HomeData
  }
`;
