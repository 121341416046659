/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'components/hocs';
import ModalLink from 'components/ModalLink';
import Dropdown from 'components/Dropdown';
import { getWeight } from 'utils/helper';
import './Footer.module.css';

const Footer = ({ data, inCart, quantity, globalActions }) => {
  const add = () => globalActions.cart.add(data);
  const changeQuantity = q => globalActions.cart.add(data, q);

  if (inCart) {
    return (
      <div styleName="root incart">
        <div />
        <div styleName="buy">
          <div styleName="buy__inner">
            <InCart data={data} quantity={quantity} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div styleName="root">
      <Dropdown data={data} onChange={changeQuantity} addToCart={globalActions.cart.add} value={quantity} />
      {data.by_request ? (
        <Unavailable />
      ) : (
        <div styleName="buy" data-cy="buy" onClick={add} role="button">
          <div styleName="buy__inner">
            <div styleName="cart-icon">
              <div styleName="cart-icon__orange" />
              <div styleName="cart-icon__white" />
            </div>
            <div styleName="label">
              <div styleName="price">
                {data.finalPrice * quantity}р
                {data.finalPrice < data.price && <span styleName="price-before">{data.price * quantity}р</span>}
              </div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div styleName="buy-text">Купить</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const InCart = ({ data, quantity }) => {
  let { freshType } = data;
  const weight = getWeight(data.weight, data.freshType, data.step);
  let newVal = Math.round(quantity * weight * 100) / 100;
  if (newVal > 1000 && freshType === 'гр') {
    newVal /= 1000;
    freshType = 'кг';
  }
  return (
    <ModalLink component="div" to="cart" styleName="in-cart-text">
      <div styleName="in-cart-icon-wrapper">
        <svg styleName="in-cart-icon" viewBox="11 7 10 15">
          <path styleName="in-cart-icon-path" d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11" />
        </svg>
      </div>
      <span>{`${newVal} ${freshType}`} в корзине</span>
    </ModalLink>
  );
};

const Unavailable = () => (
  <div styleName="unavailable">
    <span>Нет в наличии</span>
  </div>
);

Footer.propTypes = {
  data: PropTypes.object.isRequired,
  inCart: PropTypes.bool.isRequired,
  quantity: PropTypes.number.isRequired,
  globalActions: PropTypes.object.isRequired,
};

InCart.propTypes = {
  data: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
};

const selector = (state, props) => ({
  inCart: state.cart.addedIds.indexOf(props.data.entity_id) > -1,
  quantity: state.cart.quantityById[props.data.entity_id] || props.data.fresh_step || 1,
});

const enhance = connect(selector);

export default enhance(Footer);
