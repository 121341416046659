import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import FadeImage from './FadeImage';
import './Review.module.css';

const Review = ({ data, className, ...props }) => (
  <div className={className} {...props}>
    <a href={data.link} target="_blank" rel="noopener noreferrer" styleName="image-wrapper">
      <FadeImage styleName="image" src={data.photo} alt={data.author} />
      <div styleName="author-wrapper">
        <div styleName="author">@{data.author}</div>
      </div>
    </a>
  </div>
);

Review.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Review.defaultProps = {
  className: undefined,
};

export const query = graphql`
  fragment Review on Magento_Review {
    author
    link
    photo
  }
`;

export default Review;
