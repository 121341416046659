import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import FadeImage from 'components/FadeImage';
import Footer from './Footer';
import Bages from './Bages';
import Description from './Description';
import './ProductCard.module.css';

const getFontSize = s => {
  const maxLengthTitle = 200;
  const maxHackSizeFor18px = 160;
  const spanLengthHack = maxLengthTitle - (s || '').length;

  return spanLengthHack > maxHackSizeFor18px ? `${maxHackSizeFor18px}px` : `${spanLengthHack}px`;
};

const ProductCard = ({ className, data, rowIndex }) => (
  <div styleName="root" className={className}>
    <div styleName="wrapper">
      <Link styleName="more-info-wrapper" to={`/${data.normal_url_path}`}>
        <div styleName="more-info" />
      </Link>
      <div styleName="image-container">
        <Bages data={data} />
        <FadeImage
          styleName="image"
          alt={data.name}
          src={data.imageUrl}
          srcSet={`${data.retinaImageUrl} 2x`}
          initDelay={rowIndex * 500}
          lqip={data.lqip}
        />
      </div>
      <div styleName="title-wrapper" style={{ fontSize: getFontSize(data.name) }}>
        <h3 styleName="title">{data.name}</h3>
      </div>
      <Description desc={data.description} />
      <Footer data={data} />
    </div>
  </div>
);

ProductCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  rowIndex: PropTypes.number,
};

ProductCard.defaultProps = {
  className: undefined,
  rowIndex: 0,
};

export const EmptyCard = props => <div styleName="empty-card" {...props} />;

export const query = graphql`
  fragment ProductCard on Magento_CachedProduct {
    entity_id
    name
    imageUrl: mainImage(width: 285)
    retinaImageUrl: mainImage(width: 570)
    description
    weight
    fresh_start_item
    price
    finalPrice
    season {
      color
      text
    }
    text_badge
    is_link_more_info
    normal_url_path
    detox
    eco
    heavy
    raw
    shelf_life_date
    shelf_life_required
    manufacture_date
    qty
    warehouse_qty
    averageConsumption
    fresh_count_item
    fresh_type_unit
    freshType
    by_request
    position
    lqip
  }
`;

export default ProductCard;
