import React from 'react';
import PropTypes from 'prop-types';
import svg from './logo.svg';
import Icon from './Icon';

import './Logo.module.css';

const Logo = ({ className, ...props }) => <Icon className={className} styleName="logo" src={svg} {...props} />;

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: undefined,
};

export default Logo;
