import React from 'react';
import PropTypes from 'prop-types';
import ProductCard, { EmptyCard } from './ProductCard';

// eslint-disable-next-line no-unused-vars
import l from './LimitedRow.module.css';
// eslint-disable-next-line no-unused-vars
import s from './CategoryRow.module.css';

const CategoryRow = ({ products, from, to, rowIndex, ...props }) => {
  const cards = [];

  for (let i = from; i < to; i += 1) {
    const p = products[i];

    if (p) {
      cards.push(<ProductCard data={p} key={p.entity_id} rowIndex={rowIndex} />);
    } else {
      cards.push(<EmptyCard key={i} />);
    }
  }

  return (
    <div {...props} styleName="s.category l.root">
      {cards}
    </div>
  );
};

CategoryRow.propTypes = {
  products: PropTypes.array.isRequired,
  rowIndex: PropTypes.number,
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
};

CategoryRow.defaultProps = {
  rowIndex: 0,
};

export default React.memo(CategoryRow);
