import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Description.module.css';

class Description extends Component {
  state = {
    currentInterval: null,
    currentFunc: undefined,
  };

  onEnter = () => {
    if (this.state.currentInterval) {
      clearInterval(this.state.currentInterval);
    }
    const interval = setInterval(() => {
      if (this.hoDesc.scrollTop < this.hoDesc.scrollHeight - this.hoDesc.clientHeight)
        this.hoDesc.scrollTo(0, this.hoDesc.scrollTop + 1);
      else clearInterval(this.state.currentInterval);
    }, 70);
    this.setState({
      currentInterval: interval,
    });
  };

  onTouch = () => {
    this.onEnter();
    const fn = e => {
      if (this.hoDesc && !this.hoDesc.contains(e.target)) {
        this.onLeave();
        document.removeEventListener('touchend', this.state.currentFunc);
      }
    };
    this.setState({ currentFunc: fn });
    document.addEventListener('touchend', fn);
  };

  onLeave = () => {
    if (this.state.currentInterval) {
      clearInterval(this.state.currentInterval);
    }
    const interval = setInterval(() => {
      if (this.hoDesc.scrollTop > 0) {
        this.hoDesc.scrollTo(0, this.hoDesc.scrollTop - 1);
      } else {
        clearInterval(this.state.currentInterval);
      }
    }, 70);
    this.setState({
      currentInterval: interval,
    });
  };

  componentWillUnmount = () => {
    clearInterval(this.state.currentInterval);
    document.removeEventListener('touchend', this.state.currentFunc);
  };

  render() {
    const { desc } = this.props;
    return (
      <div
        styleName="hodesc"
        ref={e => {
          this.hoDesc = e;
        }}
        onMouseEnter={this.onEnter}
        onMouseLeave={this.onLeave}
        onTouchEnd={this.onTouch}
      >
        <div styleName="desc">{desc}</div>
      </div>
    );
  }
}

Description.propTypes = {
  desc: PropTypes.string.isRequired,
};

export default Description;
