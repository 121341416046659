/* eslint-disable jsx-a11y/no-autofocus */

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchContext from 'components/SearchContext';
import SearchIcon from 'components/icons/Search';
import scrollToCategory from 'utils/scrollToCategory';
import './Search.module.css';

const Search = ({ onClose, iconColor, mob, ...props }) => {
  const { query, setQuery } = useContext(SearchContext.Value);

  const reset = () => {
    setQuery('');

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    const t = setTimeout(() => scrollToCategory('search'), 1000);
    return () => clearTimeout(t);
  }, [query]);

  return (
    <div styleName="main" {...props}>
      <input
        value={query}
        onChange={e => setQuery(e.target.value)}
        styleName="search-input"
        placeholder="Поиск"
        autoComplete="off"
        autoFocus={!mob}
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        onKeyDown={e => {
          if (e.keyCode === 27) {
            reset();
          }
        }}
      />
      <div styleName="search-icon-wrapper">
        <SearchIcon size={28} color={iconColor} />
      </div>
      {onClose && (
        <button onClick={reset} type="button" styleName="cancel">
          ✕
        </button>
      )}
    </div>
  );
};

Search.propTypes = {
  onClose: PropTypes.func,
  iconColor: PropTypes.string,
  mob: PropTypes.bool,
};

Search.defaultProps = {
  onClose: undefined,
  iconColor: 'white',
  mob: false,
};

export default Search;
