import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import black from './search.svg';
import white from './search-white.svg';

const colors = { black, white };

const SearchIcon = props => <Icon src={colors[props.color]} {...props} />;

SearchIcon.propTypes = {
  color: PropTypes.string,
};
SearchIcon.defaultProps = {
  color: 'black',
};

export default SearchIcon;
